import React, { useEffect } from 'react'
import { Link, Navigate, Outlet } from 'react-router-dom'
import { useStatecontext } from '../components/contexts/ContextProvider'
import axiosClient from '../axios-client'

function DefaultLayout() {
  const { user, token, setUser, setToken } = useStatecontext()
  // useEffect(() => {
  //   axiosClient.get('/user').then(({ data }) => {
  //     setUser(data)
  //   })
  // }, [])
  // if (!token) {
  //   return <Navigate to="/" />
  // }
  // if (token) {
  //   return <Navigate to="/admin_panel" />
  // } else if (!token) {
  //   return <Navigate to="/" />
  // }
  const onLogout = (ev) => {
    ev.preventDefault()
    axiosClient.post('/logout').then(() => {
      setUser(null)
      setToken(null)
    })
  }

  return (
    <div id="defaultLayout">
      <aside>
        {/* <Link to="/dashboard">Dashboard</Link>
        <Link to="/login">Login</Link>
        <Link to="/admin_panel">Admin Panel</Link>
        <Link to="/register">Register</Link> */}

        {/* <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Signup />} />
         <Route path="*" name="Home" element={<DefaultLayout />} />
         <Route exact path="/admin_panel" name="Admin Panel" element={<AdminPanel />} />
          <Route exact path="/edit_user/:id" name="Edit User" element={<EditUser />} />
        */}
      </aside>
      <div className="content">
        {/* <header>
          <div>Header</div>
          <div>
            {user.name}
            <a href="#" onClick={onLogout} className="btn-logout">
              Log Out
            </a>
          </div>
        </header> */}
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default DefaultLayout
