import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const About = React.lazy(() => import('./views/theme/about_us/About'))
// Departments
const Department = React.lazy(() => import('./views/departments/Department'))
const AddDepartment = React.lazy(() => import('./views/departments/add_departments/AddDepartment'))
const EditDepartment = React.lazy(
  () => import('./views/departments/edit_departments/EditDepartment'),
)

// Live Systems
const Systems = React.lazy(() => import('./views/systems/core_systems/ShowSystems'))
const AddSystem = React.lazy(() => import('./views/systems/add_systems/AddSystem'))
const EditSystem = React.lazy(() => import('./views/systems/edit_systems/EditSystem'))

//Download and uploads
const Downloads = React.lazy(() => import('./views/downloads/Downloads'))
const AddFiles = React.lazy(() => import('./views/downloads/upload_files/AddFiles'))

// Test System
const Test_system = React.lazy(() => import('./views/test_systems/AllSystems'))
const AddTestSystem = React.lazy(() => import('./views/test_systems/add_test_system/AddTestSystem'))
const EditTestSystem = React.lazy(
  () => import('./views/test_systems/edit_test_system/EditTestSystem'),
)
//Client and their Projects
const Show_project = React.lazy(() => import('./views/all_projects/ShowProject'))
const AddProject = React.lazy(() => import('./views/all_projects/add_projects/AddProject'))
const EditProject = React.lazy(() => import('./views/all_projects/edit_projects/EditProject'))

const routes = [
  { path: '/dashboard', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/view', name: 'View', element: About, exact: true },
  { path: '/view/about_us', name: 'About', element: About },
  { path: '/view/departments', name: 'Department', element: Department },
  { path: '/view/add_departments', name: 'Add Department', element: AddDepartment },
  { path: '/departments/edit_department/:id', name: 'Edit Department', element: EditDepartment },
  { path: '/systems', name: 'Systems', element: Systems, exact: true },
  { path: '/systems/core_systems', name: 'core_systems', element: Systems },
  { path: '/systems/add_systems', name: 'Add System', element: AddSystem },
  { path: '/systems/edit_systems/:id', name: 'Edit System', element: EditSystem },
  { path: '/downloads', name: 'Downloads', element: Downloads },
  { path: '/download/upload_files', name: 'Add Downloads', element: AddFiles },
  { path: '/test_systems', name: 'Test System', element: Test_system, exact: true },
  { path: '/test_systems', name: 'All Systems', element: Test_system },
  { path: '/test_systems/add_test_system', name: 'Add Test System', element: AddTestSystem },
  { path: '/test_systems/edit_test_system/:id', name: 'Edit Test System', element: EditTestSystem },
  { path: '/all_projects', name: 'Show Projects', element: Show_project },
  { path: '/all_projects/add_project', name: 'Add Project', element: AddProject },
  { path: '/all_projects/edit_project/:id', name: 'Edit Project', element: EditProject },
]

export default routes
