import React, { Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import DefaultLayout from './components/DefaultLayout'
import GuestLayout from './components/GuestLayout'

const Login = React.lazy(() => import('./views/pages/login/Login'))
const Signup = React.lazy(() => import('./views/pages/register/Register'))
const EditUser = React.lazy(() => import('./views/pages/register/edit_users/EditUser'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const AdminPanel = React.lazy(() => import('./layout/AdminPanel'))
const About = React.lazy(() => import('./views/theme/about_us/About'))
const Department = React.lazy(() => import('./views/departments/Department'))
const AddDepartment = React.lazy(() => import('./views/departments/add_departments/AddDepartment'))
const EditDepartment = React.lazy(
  () => import('./views/departments/edit_departments/EditDepartment'),
)
const Systems = React.lazy(() => import('./views/systems/core_systems/ShowSystems'))
const AddSystem = React.lazy(() => import('./views/systems/add_systems/AddSystem'))
const EditSystem = React.lazy(() => import('./views/systems/edit_systems/EditSystem'))
const Downloads = React.lazy(() => import('./views/downloads/Downloads'))
const AddFiles = React.lazy(() => import('./views/downloads/upload_files/AddFiles'))
const TestSystem = React.lazy(() => import('./views/test_systems/AllSystems'))
const AddTestSystem = React.lazy(() => import('./views/test_systems/add_test_system/AddTestSystem'))
const EditTestSystem = React.lazy(
  () => import('./views/test_systems/edit_test_system/EditTestSystem'),
)
const ShowProject = React.lazy(() => import('./views/all_projects/ShowProject'))
const AddProject = React.lazy(() => import('./views/all_projects/add_projects/AddProject'))
const EditProject = React.lazy(() => import('./views/all_projects/edit_projects/EditProject'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/admin_panel', element: <AdminPanel /> },
      // { path: '/login', element: <Login /> },
      { path: '/register', element: <Signup /> },
      { path: '/edit_user/:id', element: <EditUser /> },
      { path: '/view/add_departments', element: <AddDepartment /> },
      { path: '/departments/edit_departments/:id', element: <EditDepartment /> },
      { path: '/systems/core_systems', element: <AddSystem /> },
      { path: '/systems/core_systems', element: <EditSystem /> },
      { path: '/download/upload_files', element: <AddFiles /> },
      { path: '/test_systems/add_test_systems', element: <AddTestSystem /> },
      { path: '/test_systems/edit_test_system/:id', element: <EditTestSystem /> },
      { path: '/all_projects/add_project', element: <AddProject /> },
      { path: '/all_projects/edit_project/:id', element: <EditProject /> },
    ],
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      { path: '/dashboard', element: <Dashboard /> },
      { path: 'about_us', element: <About /> },
      { path: 'departments', element: <Department /> },
      { path: 'systems', element: <Systems /> },
      { path: '/login', element: <Login /> },
      { path: 'downloads', element: <Downloads /> },
      { path: 'test_systems', element: <TestSystem /> },
      { path: 'all_projects', element: <ShowProject /> },
    ],
  },
])

export default router
