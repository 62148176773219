import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CBadge } from '@coreui/react'
import { startTransition } from 'react'

export const AppSidebarNav = ({ items }) => {
  const location = useLocation()

  // Function to render a single navigation item
  const navItem = (item, index, indent = false) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component

    return (
      <Component
        {...(rest.to && !rest.items && { component: NavLink })}
        key={index}
        {...rest}
        onClick={() => {
          startTransition(() => {
            // Your click handling logic goes here
          })
        }}
      >
        {/* Render the navigation link */}
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </Component>
    )
  }

  // Function to render a group of navigation items
  const navGroup = (item, index) => {
    const { component, name, icon, items, to, ...rest } = item
    const Component = component

    return (
      <Component
        compact
        idx={String(index)}
        key={index}
        toggler={<span>{name}</span>}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {/* Recursively render nested navigation groups or items */}
        {items &&
          items.map((item, index) =>
            item.items ? navGroup(item, index) : navItem(item, index, true),
          )}
      </Component>
    )
  }

  // Render the sidebar navigation
  return (
    <React.Fragment>
      {Array.isArray(items) ? (
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))
      ) : (
        // Handle the case where items is not an array
        <div>Error: Invalid navigation items provided</div>
      )}
    </React.Fragment>
  )
}

// Prop types validation
AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default AppSidebarNav
