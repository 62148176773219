import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStatecontext } from '../components/contexts/ContextProvider'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

function GuestLayout() {
  const { token } = useStatecontext()
  // if (token) {
  //   return <Navigate to="/admin_panel" />
  // }
  return (
    // <div>
    //   <Outlet />
    // </div>
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <div className="body flex-grow-1">
          <Outlet />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default GuestLayout
