import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCloudDownload,
  cilCursor,
  cilPeople,
  cilRoom,
  cilSpeedometer,
  cilStar,
  cilBank,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      // text: 'NEW',
    },
  },
  {
    component: CNavTitle,
    name: 'enconsult',
  },
  {
    component: CNavItem,
    name: 'About',
    to: 'about_us',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Departments',
    to: 'departments',
    icon: <CIcon icon={cilRoom} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Components',
  },

  {
    component: CNavGroup,
    name: 'Systems',
    to: 'systems',
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'core_systems',
        to: 'systems',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Projects',
    to: 'all_projects',
    icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Downloads',
    to: 'downloads',
    icon: <CIcon icon={cilCloudDownload} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Test System',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'All systems',
        to: 'test_systems',
        badge: {
          color: 'info',
          text: 'New',
        },
      },
    ],
  },
]

export default _nav
