import React from 'react'
import { CDropdown, CDropdownToggle } from '@coreui/react'

const AppHeaderDropdown = () => {
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0 pe-0" caret={false}></CDropdownToggle>
    </CDropdown>
  )
}

export default AppHeaderDropdown
